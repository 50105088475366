import React, { useEffect, useState } from 'react';
import { fetchCategories } from '../api/hooks/apiService';

const ProductCategories = ({ onCategoryChange }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null); // 管理选中的分类

  useEffect(() => {
    // 获取分类数据，只执行一次
    const loadCategories = async () => {
      try {
        const response = await fetchCategories();
        const data = response.data;
        data.unshift({ id: 'all', name: '全部', created_at: Date.now() });
        setCategories(data);
        if (data.length > 0) {
          console.log('触发选中分类');
          setSelectedCategory(data[0].id); // 初次加载时自动选择第一个分类
          onCategoryChange(data[0].id); // 触发父组件的分类变化
        }
      } catch (err) {
        setError(err.message);
      }
    };

    loadCategories();
  }, [onCategoryChange]); // 将 onCategoryChange 添加到依赖数组中

  const handleCategoryClick = (id) => {
    setSelectedCategory(id); // 更新选中的分类
    onCategoryChange(id); // 触发父组件的分类变化
  };

  return (
    <div className="bg-white p-4 rounded">
      {error && <p className="text-red-500">{error}</p>}
      <h2 className="text-xl font-bold mb-4 text-center md:text-left">选择分类</h2>
      <div className="flex flex-wrap justify-start md:mx-8 gap-4 cursor-pointer">
        {!categories || categories.length <= 0 ? (
          <p className="text-red-500">数据加载中...</p>
        ) : (
          categories.map((category) => (
            <div
              key={category.id}
              className={`flex flex-col px-4 py-2 text-left rounded bg-gray-100 ${category.id === selectedCategory ? 'bg-purple-300 border-purple-400 text-white' : 'text-gray-700'
                }`}
              onClick={() => handleCategoryClick(category.id)}
            >
              <div className="flex-grow-1 text-sm">
                <h3 className="font-medium mb-2 md:mb-0">{category.name}</h3>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProductCategories;
