import React from 'react';
import { Link } from 'react-router-dom';

const Product = ({ product }) => {
  return (
    <div className="border border-purple-200 px-5 py-5 bg-white rounded-xl shadow-lg shadow-purple-300/50 sm:py-6 md:space-y-4 md:max-w-80">  
      <div className="flex sm:justify-between sm:space-x-6">  
        <img  
          className="block mx-auto h-12 rounded-full"  
          src={product.image_url}
          alt="ICON"  
        />  
        <div className="flex-1 text-left pl-5">  
          <div>  
            <p className="text-lg font-semibold text-black">  
              {product.name}  
              <span className="text-gray-500 font-normal text-xs">({product.description})</span>  
            </p>  
            <p className="text-base text-rose-700 font-medium">￥{product.price}</p>  
          </div>  
          <div className="flex justify-between mt-3 sm:mt-0">  
            <span className="text-emerald-700 text-sm">剩余{product.stock}件</span>
            <Link to={`/detail/${product.id}`}>
            <button className="px-4 py-1 text-xs text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent"
            >购买</button>
            </Link>
          </div>  
        </div>  
      </div>  
    </div>
  );
};

export default Product;