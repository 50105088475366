import React, { useState, useEffect,useCallback } from 'react';
import ProductCategory from '../components/ProductCategory';
import Pagination from '../components/Pagination';
import ProductList from '../components/ProductList';
import { fetchProducts } from '../api/hooks/apiService';

const List = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async (category, page) => {
    try {
      const response = await fetchProducts(category, page);
      setProducts(response.data);
      setTotalCount(response.totalCount);
      // 清除错误状态
      setError(null);
    } catch (err) {
      setError(err); // 处理错误
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchData(selectedCategory, currentPage);
    }
  }, [selectedCategory, currentPage]);

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  },[]);

  return (
    <div className="container mx-auto p-4">
      <ProductCategory onCategoryChange={handleCategoryChange} />
      <ProductList products={products} error={error} />
      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}

export default List;