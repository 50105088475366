import React from 'react';

const Pagination = ({ currentPage, totalCount, onPageChange }) => {
  // 假设每页显示 10 个项目
  const perPage = 10;
  const totalPages = Math.ceil(totalCount / perPage);
  // 最多显示的页码数量
  const maxVisiblePages = 5;

  // 计算要显示的页码范围
  const getPageNumbers = () => {
    const pages = [];
    let startPage, endPage;

    if (totalPages <= maxVisiblePages) {
      // 如果总页数小于等于最大可见页数，显示所有页码
      startPage = 1;
      endPage = totalPages;
    } else {
      // 计算起始和结束页码
      if (currentPage <= Math.ceil(maxVisiblePages / 2)) {
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + Math.floor(maxVisiblePages / 2) >= totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxVisiblePages / 2);
        endPage = currentPage + Math.floor(maxVisiblePages / 2);
      }
    }

    // 确保页码在有效范围内
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex justify-center items-center my-4">
      <div className="text-gray-600">
        第 {(currentPage - 1) * perPage + 1}页，每页显示 {Math.min(currentPage * perPage, totalCount)}条， 共 <b>{totalCount}</b> 条
      </div>
      <div className="flex justify-center items-center">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 mx-1 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50`}
        >上一页
        </button>

        {/* 显示页码 */}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`px-4 py-2 mx-1 text-white rounded-lg ${page === currentPage ? 'bg-blue-600' : 'bg-blue-500 hover:bg-blue-600'
              }`}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 mx-1 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50`}
        >下一页
        </button>
      </div>
    </div>
  );
};

export default Pagination;
