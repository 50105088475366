import React from 'react';  
  
const TopBar = () => {
  return (
    <nav className="bg-white shadow-lg shadow-purple-300/50 mb-7">
      <div className="container mx-auto flex items-center justify-between flex-wrap p-3">
        <div className="flex items-center flex-shrink-0 text-center">  
          <a href="/" className="text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap text-gray-800">  
            <img src="/images/logo.png" alt="Logo" className="h-10" />
          </a>  
          <div className="text-sm">
            <div className="md:text-xl  font-bold leading-relaxed inline-block py-2 whitespace-no-wrap text-gray-800">  
            你的解忧杂货铺(Variety store)
            </div>  
          </div>  
        </div>  
  
        <div className="flex items-center">
          <button className="flex justigy-start hover:text-purple-800 text-gray-800 font-bold py-2 px-4 rounded align-bottom">
            <svg t="1725268954906" className="icon mt-1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="25714" width="16" height="16"><path d="M923.4 339.2c-22.6-53.3-54.8-101.2-96-142.3-41.1-41.1-89-73.4-142.3-96-55.2-23.4-113.8-35.2-174.3-35.2s-119.1 11.8-174.3 35.2c-53.3 22.6-101.2 54.8-142.3 96-41.1 41.1-73.4 89-96 142.3C74.8 394.4 63 453.1 63 513.5s11.8 119.1 35.2 174.3c22.6 53.3 54.8 101.2 96 142.3 41.1 41.1 89 73.4 142.3 96 55.2 23.4 113.9 35.2 174.3 35.2 60.4 0 119.1-11.8 174.3-35.2 53.3-22.6 101.2-54.8 142.3-96 41.1-41.1 73.4-89 96-142.3 23.4-55.2 35.2-113.9 35.2-174.3 0-60.4-11.8-119.1-35.2-174.3zM510.8 880.4c-202.3 0-366.9-164.6-366.9-366.9s164.6-366.9 366.9-366.9 366.9 164.6 366.9 366.9-164.5 366.9-366.9 366.9z" p-id="25715" fill="#202937"></path><path d="M604.4 555.3c15.4-24.4 24.3-53.2 24.3-84.1 0-87.1-70.9-158-158-158s-158 70.9-158 158 70.9 158 158 158c28.5 0 55.3-7.6 78.4-20.9l94.5 94.5c14.9 14.9 39.2 14.9 54.1 0 14.9-14.9 14.9-39.2 0-54.1l-93.3-93.4z m-133.6-2.2c-45.2 0-82-36.8-82-82s36.8-82 82-82 82 36.8 82 82-36.8 82-82 82z" p-id="25716" fill="#202937"></path></svg>
            <span className="pl-1">查看订单</span>
          </button>
          <button className="flex justigy-start hover:text-purple-800 text-gray-800 font-bold py-2 px-4 rounded ml-4">
            <svg t="1725269384655" className="icon mt-1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30971" id="mx_n_1725269384658" width="16" height="16"><path d="M512 978.773333a62.08 62.08 0 0 1-28.16-6.613333l-5.12-2.986667L370.346667 896H128a128 128 0 0 1-128-128V170.666667a128 128 0 0 1 128-128h213.333333a42.666667 42.666667 0 0 1 0 85.333333H128a42.666667 42.666667 0 0 0-42.666667 42.666667v597.333333a42.666667 42.666667 0 0 0 42.666667 42.666667h242.986667a85.333333 85.333333 0 0 1 37.76 8.746666l5.12 2.986667 98.133333 66.133333 94.933333-68.48a85.333333 85.333333 0 0 1 38.4-9.386666H896a42.666667 42.666667 0 0 0 42.666667-42.666667V170.666667a42.666667 42.666667 0 0 0-42.666667-42.666667H597.333333a42.666667 42.666667 0 0 0-42.666666 42.666667v512a42.666667 42.666667 0 0 1-85.333334 0V170.666667a128 128 0 0 1 128-128h298.666667a128 128 0 0 1 128 128v597.333333a128 128 0 0 1-128 128H646.186667l-104.96 75.733333a64 64 0 0 1-29.226667 7.04z" p-id="30972" fill="#27303D"></path><path d="M170.666667 298.666667m42.666666 0l128 0q42.666667 0 42.666667 42.666666l0 0q0 42.666667-42.666667 42.666667l-128 0q-42.666667 0-42.666666-42.666667l0 0q0-42.666667 42.666666-42.666666Z" p-id="30973" fill="#27303D"></path><path d="M170.666667 469.333333m42.666666 0l128 0q42.666667 0 42.666667 42.666667l0 0q0 42.666667-42.666667 42.666667l-128 0q-42.666667 0-42.666666-42.666667l0 0q0-42.666667 42.666666-42.666667Z" p-id="30974" fill="#27303D"></path></svg>
            <span className="pl-1">使用教程</span>
          </button>
          <button className="flex justigy-start hover:text-purple-800 text-gray-800 font-bold py-2 px-4 rounded ml-4">
            <svg t="1725269489881" className="icon mt-1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="33040" width="16" height="16"><path d="M837.632 395.264A326.144 326.144 0 0 0 512 102.4a318.976 318.976 0 0 0-325.632 292.864A122.368 122.368 0 0 0 102.4 512v81.92A121.344 121.344 0 0 0 220.672 716.8a122.368 122.368 0 0 0 123.392-122.88V512a117.248 117.248 0 0 0-69.12-110.592A232.448 232.448 0 0 1 512 184.32a227.84 227.84 0 0 1 231.936 217.088A117.248 117.248 0 0 0 674.816 512v81.92a122.88 122.88 0 0 0 41.984 91.648v56.832a98.304 98.304 0 0 1-102.4 97.28h-24.576a40.96 40.96 0 0 0 0 81.92H614.4a182.272 182.272 0 0 0 182.784-179.2V716.8A125.952 125.952 0 0 0 921.6 593.92V512a129.024 129.024 0 0 0-83.968-116.736zM265.216 593.92a42.496 42.496 0 0 1-44.544 40.96 41.472 41.472 0 0 1-39.424-40.96V512a41.472 41.472 0 0 1 39.424-40.96 42.496 42.496 0 0 1 44.544 40.96z m572.416 0a41.472 41.472 0 0 1-39.424 40.96 42.496 42.496 0 0 1-44.544-40.96V512a42.496 42.496 0 0 1 44.544-40.96 41.472 41.472 0 0 1 39.424 40.96z" p-id="33041" fill="#27303D"></path></svg>
            <span className="pl-1">在线客服</span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;