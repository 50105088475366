import React from 'react';  
import Product from './Product';  
  
const ProductList = ({products, error}) => {

  return (
    <div className="bg-white p-4 rounded">
      <h2 className="text-xl font-bold mb-4 text-center md:text-left">选择商品</h2>
      {error ?(
        <p className="text-red-500">没有商品</p> // 显示错误信息
        ):(<div className="flex flex-wrap justify-start md:mx-8 gap-4 cursor-pointer ">
        {/*产品列表*/}
        {products.map(product => (
          <Product key={product.id} product={product} />
        ))}
      </div>)
      }
    </div>
  );  
};  
  
export default ProductList;