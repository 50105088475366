import axios from 'axios';

// 创建 Axios 实例
const apiClient = axios.create({
  baseURL: 'https://allinone.doyi.top/api', // 替换为你的 API 基础 URL
  timeout: 10000, // 设置请求超时时间
});

// 请求拦截器
apiClient.interceptors.request.use(
  (config) => {
    // 在这里可以添加请求头或其他配置
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
apiClient.interceptors.response.use(
  (response) => {
    // 处理响应数据
    return response.data; // 直接返回数据
  },
  (error) => {
    // 处理响应错误
    const errorMessage = error.response ? error.response.data.message : error.message;
    console.error('API Error:', errorMessage);
    return Promise.reject(errorMessage); // 返回错误信息
  }
);

// 调用获取分类的 API
export const fetchCategories = async () => {
  return await apiClient.get('/categories'); // 
};
// 调用获取产品的 API
export const fetchProducts = async (id, page,limit?=10) => {
  return await apiClient.get(`products?category_id=${id}&page=${page}&limit=${limit}`); 
};

// 调用获取产品详情的 API
export const fetchProductDetail = async (id) => {
  return await apiClient.get(`/product/${id}`); 
};
