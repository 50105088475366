// ProductDetail.js  
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fetchProductDetail } from '../api/hooks/apiService';

const ProductDetail = () => {
  // 初始化详情页参数动态变量
  const [email, setEmail] = useState('');
  const [queryPassword, setQueryPassword] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(null);
  const [err, setErr] = useState(null);
  const [product, setProduct] = useState(null);

  // 使用 useParams 钩子获取路由中的动态参数 id
  const { id } = useParams();

  useEffect(() => {
    // 组件加载时获取商品详情
    const loadDetail = async () => {
      try {
        // 调用获取分类的 API
        const response = await fetchProductDetail(id);
        // 设置分类数据
        const data = response.data;
        setProduct(data);
      } catch (err) {
        setError(err);
      }
    };
    loadDetail();
  }, [id]);

  if (error) {
    return <p className="text-red-500">Error: {error}</p>; // 显示错误信息
  }

  if (!product) {
    return <p>数据加载中...</p>; // 数据加载时显示的提示
  }

  // 数量实现增加、减少
  // Function to handle the increment of the quantity
  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };
  // Function to handle the decrement of the quantity
  const handleDecrement = () => {
    // Ensure that the quantity does not drop below 1
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };
  // Function to handle changes in the input field directly
  const handleChange = (event) => {
    const value = Math.max(1, parseInt(event.target.value) || 1);
    setQuantity(value);
  };

  // 页面数据交验
  // Function to validate the inputs
  const validateInputs = () => {
    if (!email) {
      setErr(err + '邮箱不能为空');
      return false;
    }
    if (!queryPassword) {
      setErr(err + '查询密码不能为空');
      return false;
    }
    setError('');
    return true;
  };

  // 处理form表单提交
  const handleSubmit = async (paymentMethod) => {
    if (!validateInputs()) {
      return;
    }

    const postData = {
      email,
      queryPassword,
      quantity,
      paymentMethod,
    };

    try {
      // Example API endpoint, replace with your actual endpoint
      const response = await axios.post('/api/payment', postData);
      console.log('Payment response:', response.data);
      alert('支付成功!');
    } catch (error) {
      console.error('支付失败:', error);
      alert('支付失败，请重试。');
    }
  };

  return (
    <div className="container mx-auto my-8 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-gray-700 mb-4">商品详情</h1>
      <div className="flex flex-wrap">
        <div className="md:w-1/3 p-4">
          <img src="/images/discord.png" alt={product.name} className="h-auto rounded-lg" />
        </div>
        <div className="md:w-2/3 p-4 text-left">
          <h2 className="text-xl font-semibold text-gray-800">{product.name}</h2>
          <div className="flex justify-between">
            <p className="text-red-600 text-2xl font-bold">¥{product.price}</p>
            <p className="text-green-600 mt-2">库存: {product.stock}件</p>
          </div>
          <div className="flex mt-4">
            <span className="inline-block text-gray-600 mr-2 leading-loose">购买数量:</span>
            <div className="flex items-center">
              <button
                className="px-3 py-1 text-lg font-bold text-gray-700 bg-gray-200 rounded-lg"
                onClick={handleDecrement}
              >-</button>
              <input type="number" className="mx-2 w-16 text-center border-gray-300 rounded-lg"
                value={quantity}
                onChange={handleChange}
              />
              <button className="px-3 py-1 text-lg font-bold text-gray-700 bg-gray-200 rounded-lg"
                onClick={handleIncrement}>+</button>
            </div>
          </div>
          <div className="mt-4">
            <span className="inline-block text-gray-600 mb-2 mr-2">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱:</span>
            <input type="email" className="p-2 border border-gray-300 rounded-lg"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="填写你的邮箱" />
          </div>
          <div className="mt-4">
            <span className="inline-block text-gray-600 mb-2 mr-2">优&nbsp;&nbsp;惠&nbsp;&nbsp;码:</span>
            <input type="text" className="p-2 border border-gray-300 rounded-lg" placeholder="没有可以不填" />
          </div>
          <div className="mt-4">
            <span className="inline-block text-gray-600 mb-2 mr-2">查询密码:</span>
            <input type="text" className="p-2 border border-gray-300 rounded-lg"
              onChange={(e) => setQueryPassword(e.target.value)}
              placeholder="请填写订单的查询密码" />
          </div>
          <div className="mt-4 flex items-center">

            <span className="text-gray-600 mr-2">帮助中心:</span>
            <span><input type="checkbox" className="mr-2" defaultChecked /> 使用教程</span>
          </div>
          <div className="mt-8 flex space-x-4">
            <button className="flex items-center justify-center px-4 py-2 text-purple rounded-lg border border-purple-200 focus:text-white focus:bg-purple-600 focus:border-transparent"
              onClick={() => handleSubmit('Alipay')}
            >支付宝下单</button>
            <button className="flex items-center justify-center px-4 py-2 text-purple rounded-lg border border-purple-200 focus:text-white focus:bg-purple-600 focus:border-transparent"
              onClick={() => handleSubmit('USDT')}
            >usdt下单</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;