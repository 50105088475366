import React from 'react';
import TopBar from '../components/TopBar';
import TopFooter from '../components/TopFooter';

const MainLayout = ({ children }) => {
  return (
    <div>
      <TopBar/>
      <main>{children}</main>
      <TopFooter/>
    </div>
  );
};

export default MainLayout;