const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(module => {
        console.log('web-vitals module:', module); // 打印导入的模块
        const { getCLS, getFID, getFCP, getLCP, getTTFB } = module;
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      .catch(err => {
        console.error('Failed to load web-vitals:', err);
      });
  }
};

export default reportWebVitals;
