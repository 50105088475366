// src/routes/index.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import About from '../pages/About';
import List from '../pages/List';
import Detail from '../pages/Detail';

const AppRoutes = () => {
  return (
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <List /> {/* Home 页面作为 children 传递给 MainLayout */}
            </MainLayout>
          }
        />
        <Route
          path="/list"
          element={
            <MainLayout>
              <List /> {/* Home 页面作为 children 传递给 MainLayout */}
            </MainLayout>
          }
        />
        <Route
          path="/about"
          element={
            <MainLayout>
              <About /> {/* About 页面作为 children 传递给 MainLayout */}
            </MainLayout>
          }
        />
        <Route
          path="/detail/:id"
          element={
            <MainLayout>
              <Detail /> {/* Contact 页面作为 children 传递给 MainLayout */}
            </MainLayout>
          }
        />
      </Routes>
  );
};

export default AppRoutes;
