import React from 'react';  
  
const TopFooter = () => {  
  return (  
    <footer className="bg-gray-100 text-black-400 text-sm py-10 mt-10 shadow-lg shadow-purple-300/50 border border-gray-300">  
      <div className="w-2/3 mx-auto">
        <div className="flex flex-wrap justify-around text-xl border-b-2 pb-8">  
          <div>
            <img className="inline-block h-6" alt="icon" src="/images/source.svg"/>
            <span className="hover:text-purple ml-2">精品货源</span>
          </div>
          <div>
            <img className="inline-block h-7" alt="icon"  src="/images/auto-shopping.svg"/>
            <span className="hover:text-purple ml-2">自动发货</span>
          </div>
          <div>
            <img className="inline-block h-7" alt="icon"  src="/images/quality.svg"/>
            <span className="hover:text-purple ml-2">品质保证</span>
          </div>
          <div>
            <img className="inline-block h-8" alt="icon"  src="/images/online-talk.svg"/>
            <span className="hover:text-purple ml-2">在线咨询</span>
          </div>
            
        </div>  
        <div className="flex justify-start py-5 text-left">
          <div className="flex flex-col gap-y-4 w-1/2">
            <h2 className="text-xl font-media font-bold">服务</h2>
            <p className="text-gray-700 hover:text-purple">自助商城</p> 
            <p className="text-gray-700 hover:text-purple">帮助中心（教程）</p>  
          </div>
          <div className="flex flex-col gap-y-4 w-1/2">
            <h2 className="text-xl font-bold">外部链接</h2>
            <p className="text-gray-700 hover:text-purple">AppleID账号管理</p>  
          </div>
        </div>
        <div className="mt-4">  
          <p>Copyright © 2019-2024 Variety store. Inc</p>  
        </div> 
      </div> 
    </footer>  
  );  
};  
  
export default TopFooter;